
import Login from './Components/Auth/login'
import Dashboard from './Components/Dashboard/dashboard';
import './App.css'
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PrivateRoute from '../src/lib/privateRoute'
import MainImage from "../src/assets/img/mainscreen.jpg"
function App() {

  return (
    <BrowserRouter >
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/dashboard" exact component={Dashboard} />

      </Switch>
    </BrowserRouter >
  );
}

export default (App);