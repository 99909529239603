
import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Invoice from "../../assets/img/invoice.png"

import Rectangle from "../../assets/img/rectangle.svg"
import Typography from '@mui/material/Typography';
import { ForkLeft } from '@mui/icons-material';
export default function Login() {
  return (
    <>

      <div className='d-flex landing'>
        {/* <img className="  " src={Navbar} /> */}
        <div>
          <div className="  " style={{
            // marginLeft: "853px",
            // marginTop: "40px",
            minWidth: 458,
            minHeight: 404,
            boxShadow: 3,
            width: '8rem',
            height: '5rem',
            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
            color: (theme) =>
              theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
            p: 1,
            m: 1,
            borderRadius: '16px',
            textAlign: 'center',
            fontSize: '0.875rem',
            fontWeight: '700',
          }} src={""} />

          <div className='d-flex '>
            <div className='formcard1'>

              <Card
                style={{ marginTop: "-330px", backgroundColor: "#FFF4DB" }}
                sx={{
                  marginLeft: "1700px",
                  minWidth: 500,
                  minHeight: 500,
                  boxShadow: 3,
                  width: '8rem',
                  height: '5rem',
                  m: 1,
                  borderRadius: '16px',
                  textAlign: 'center',
                  fontSize: '0.875rem',
                  fontWeight: '700',
                }}

              >
                <CardContent>
                  <form action="#">
                    <img className="" style={{
                      width: "50%",
                      height: "50%",
                      objectFit: "cover"
                    }} src={Invoice} />
                  </form>
                </CardContent>

              </Card>
            </div>
            <div className='formcard'>
              <Card
                style={{ marginTop: "-330px", backgroundColor: "#FFFF" }}
                sx={{

                  marginLeft: "1200px",
                  minWidth: 500,
                  minHeight: 500,
                  boxShadow: 3,
                  width: '8rem',
                  height: '5rem',
                  m: 1,
                  borderRadius: '16px',
                  textAlign: 'center',
                  fontSize: '0.875rem',
                  fontWeight: '700',
                }}

              >
                <CardContent>
                  <form action="#">
                    {/* <Typography variant="h5" component="div"> */}
                    <div className="form-group" id="4">
                      <label className='inputlabelform' >Invoice Name
                      </label>
                      <input className='forminput' placeholder="Enter invoice name"  />
                    </div>
                    <div className="form-group" id="5">
                      <label className='inputlabelform' >Invoice Number</label>
                      <input className='forminput' placeholder="Enter invoice number"  />
                    </div>
                    <div className="form-group" id="6">
                      <label className='inputlabelform' >Tax</label>
                      <input className='forminput' placeholder="Enter total tax paid"  />
                    </div>
                    <div className="form-group" id="7">
                      <label className='inputlabelform' >Total Bill amount</label>
                      <input className='forminput' placeholder="Enter total bill amount"  />
                    </div>
                    <div className="form-group" id="8">
                      <label className='inputlabelform' >Billed to</label>
                      <input className='forminput' placeholder="Enter payee name"  />
                    </div>
                    <div className="form-group" id="9">
                      <label className='inputlabelform' for="email">Billed by</label>
                      <input className='forminput' placeholder="invoice issued by"  />
                    </div>
                    <div className="submi-form-button d-flex" >
                      <button className='me-2 back-btton' id="10"type="button">Back</button>
                      <button type="button" id="11" className='save-btton'>Save & Continue</button>
                    </div>
                  </form>
                </CardContent>

              </Card>
            </div>


          </div>

        </div>

      </div>

    </>

  )
}
