
import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Navbar from "../../assets/img/navbar.png"

import Rectangle from "../../assets/img/rectangle.svg"
import Typography from '@mui/material/Typography';
import { ForkLeft } from '@mui/icons-material';
export default function Login() {
    return (
        <>

            <div className='d-flex login'>
                {/* <img className="  " src={Navbar} /> */}
                <div>
                    <img className="  mainScreen" style={{
                        marginLeft: "853px",
                        marginTop: "110px",
                        minWidth: 458,
                        minHeight: 404,
                        boxShadow: 3,
                        width: '8rem',
                        height: '5rem',
                        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                        color: (theme) =>
                            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                        p: 1,
                        m: 1,
                        borderRadius: '16px',
                        textAlign: 'center',
                        fontSize: '0.875rem',
                        fontWeight: '700',
                    }} src={Rectangle} />
                    <div style={{ marginLeft: "945px", marginTop: "-350px" }}>
                        <form action="#">
                            <h1>Sign In</h1>
                            <div className="form-group" id="1">
                                <label className='inputlabel' for="email">Email</label>
                                <input type="email" id="email" name="email" placeholder="enter your Email here" required aria-label="Email address input field" />
                            </div>
                            <div className="form-group" id="2">
                                <label className='inputlabel' for="email">Password</label>
                                <input type="email" id="email" name="email" placeholder="enter your Password here" required aria-label="Email address input field" />
                            </div>
                            <div className="submitbutton" id="3">
                                <button type="submit">Sign In</button>
                            </div>
                        </form>
                    </div>

                    {/* <Card

                        sx={{

                            marginLeft: "1700px",
                            minWidth: 439,
                            minHeight: 354,
                            boxShadow: 3,
                            width: '8rem',
                            height: '5rem',
                            m: 1,
                            borderRadius: '16px',
                            textAlign: 'center',
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}

                    >
                        <CardContent>
                            <form action="#">
                                <h1>Sign In</h1>
                                <Typography variant="h5" component="div">
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">


                                </Typography>
                              </form>
                        </CardContent>

                    </Card> */}


                </div>

            </div>

        </>

    )
}
